.toggle-section {
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 70px;
    align-items: center;

    .toggle-title {
        outline: none;
        color: #303030;
        border: none;
        background: transparent;
        padding: 0;
    }

    .toggle-title-active {
        outline: none;
        border: none;
        background: transparent;
        padding: 0;
        color: $accent-color;
    }

    .toggle-buttons {
        display: flex;
        flex-direction: row;
        gap: 70px;
    }

    .toggle-content {
        display: flex;
        gap: 20px;
        flex-direction: column;
        align-items: flex-start;

        .btn-default {
            font-size: 16px;
            font-weight: $font-weight-semi-bold;
            padding: 8px 26px;
        }
    }
}