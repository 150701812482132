.Contact {
    position: relative;
    overflow: hidden;
}

.contact-section {
    margin: 0 12.5%;
    padding-top: 57px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 72px;
}


.contact-box {
    background: #FFFFFF;
    box-shadow: 0px 141px 200px -80px rgba(25, 58, 75, 0.3);
    border-radius: 50px;
    width: 100%;
    height: 750px;
    z-index: 10;
    position: relative;
    display: grid;
    grid-template-columns: 70% 30%;

    .bg-left {
        background-color: $light-color;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    .bg-right {
        background-color: $accent-color;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
    }
}

.contact-display-contnet {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 9%;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
}

.contact-form {
    width: 100%;

    .contact-input {
        font-family: 'Montserrat';

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
        .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
        .css-p0rm37,
        .MuiInputLabel-root {
            font-family: 'Montserrat';
        }

        .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
        .MuiOutlinedInput-notchedOutline,
        .css-igs3ac {
            border-color: #E0E0E0;
        }

        .css-wgai2y-MuiFormLabel-asterisk,
        .MuiFormLabel-asterisk,
        .MuiInputLabel-asterisk,
        .css-sp68t1 {
            color: red !important;
        }

        .css-2xabbf-MuiAutocomplete-root {
            width: 100%;
        }
    }

    .contact-description {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
    }

    .contact-header {
        display: flex;
        align-items: baseline;
        font-weight: $font-weight-bold;
        font-size: 54px;
        gap: 10px;

        h1 {
            font-weight: $font-weight-bold;
            font-size: 54px;
            margin: 0;
        }

        span {
            color: $accent-color;
        }
    }

    .contact-text {
        margin: 0;
        font-size: 14px;
        font-weight: $font-weight-semi-bold;
        line-height: 24px;
        letter-spacing: 0.01em;
    }

    .btn-contact {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: $font-weight-bold;
        padding: 15px;
        font-size: 16px;
        line-height: 20px;
        width: 100%;
        text-transform: uppercase;
        background-color: $accent-color;
    }
}

.contact-map {
    background-image: url('../../img/contact/map.jpg');
    background-size: cover;
    width: 450px;
    height: 580px;
    background-position: center;

    iframe {
        width: 450px;
        height: 580px;
        border: none;
        object-fit: cover;
    }

    .contact-map-skeleton {
        width: 450px;
        height: 580px;
        object-fit: cover;
    }
}

.grid-address {
    max-width: 200px;
}

.grid-email {
    max-width: 190px;
}

.bg-blur-1 {
    position: absolute;
    left: 74.85%;
    right: -71.85%;
    top: 11.49%;
    bottom: -72.85%;
    background: #DCF3FF;
    background-color: #DCF3FF;
    filter: blur(200px);
    -webkit-filter: blur(200px);
}

.bg-blur-2 {
    position: absolute;
    left: -26.7%;
    right: 30.56%;
    top: -13.93%;
    bottom: -21.12%;
    background: #CFDBE4;
    background-color: #CFDBE4;
    filter: blur(200px);
    -webkit-filter: blur(200px);
    transform: matrix(0.97, -0.24, 0.18, 0.98, 0, 0);
    -webkit-transform: matrix(0.97, -0.24, 0.18, 0.98, 0, 0);
}

.bg-blur-3 {
    position: absolute;
    left: 38.42%;
    right: 21.94%;
    top: 27.18%;
    bottom: -1.05%;
    background: #DBEEFF;
    background-color: #DBEEFF;
    filter: blur(200px);
    -webkit-filter: blur(200px);
}

.grid-bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 !important;
    width: auto !important;
    gap: 10px;
    align-items: center;

    img {
        width: 26px;
        height: 26px;
        object-fit: contain;
    }

    .grid-address {
        max-width: 200px;
    }

    .grid-info {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .grid-title {
            font-size: 14px;
            font-weight: $font-weight-semi-bold;
            text-transform: uppercase;
            margin: 0;
        }

        a {
            font-size: 14px;
            margin: 0;
            color: #0A66C2;

            line-height: initial;
        }
    }


}

.contact-bottom-section {
    width: 100%;
    min-height: 77px;
    margin: 0;
    .react-reveal {
        animation-duration: 800ms !important;
    }
}

.captcha-box {
    min-width: 304px;
    min-height: 78px;
}

.contact-bottom-info {
    padding-top: 20px;
    margin: 0 !important;
    width: 100%;
    flex-wrap: nowrap;
    gap: 15px;
    justify-content: space-between;
}


.contact-logo {
    z-index: 11;
    position: relative;

    .site-footer__logo {
        display: flex;
        justify-content: center;

        img {
            height: 60px;
            width: auto;
        }
    }
}