.Projects-section {
    padding: 100px 10.5%;
    display: flex;
    position: relative;
    overflow: hidden;
    gap: 30px;
    flex-direction: column;
    
    .Page-header-section {
        margin: 0px 19%;
        margin-bottom: 30px;
    }

    .projects-boxes {
        margin-bottom: 0;
    }
    
    .project-boxes-content {
        min-height: 539px;
        align-items: center;
        gap: 50px;
        
        .buttoni-bottom {
            margin: 0;
        }
    }

    .animation-img-bg {
        .animation-img {
            position: absolute;
            top: 240px;
            width: 760px;
        }
    }
}