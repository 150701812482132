/*
// .nav-bar
*/
@import '../variables';
@import '../functions';
@import '../mixins/direction';


.nav-bar {
    height: $nav-bar-height;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    background: $nav-bar-bg;
    box-shadow: $nav-bar-shadow;
    color: $nav-bar-font-color;
}

.nav-title {
    font-weight: $font-weight-medium;
    transition: 0.2s ease-in-out;

    &:hover {
        color: $accent-color;
        transition: 0.2s ease-in-out;
    }
}

.nav-barscrolled {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    height: $nav-bar-height;
    background: $nav-bar-bg;
    box-shadow: $nav-bar-shadow;
    color: $nav-bar-font-color;

    .site-header__logoscroll {
        margin-bottom: 0px;
    }

    .site-header__logoscroll img {
        max-height: 50px;
    }

}

.nav-barscrolled > .nav-bar__container > .nav-bar__row {
    justify-content: center !important;
}

.nav-bar--stuck {
    z-index: 10;
    width: 100%;
    position: fixed;
    top: 0;
    box-shadow: $nav-bar-stuck-shadow;
}
.nav-bar__container {
    display: flex;
    margin: 0 13.5%;
    gap: 200px;
    justify-content: space-between;
    height: 100%;
}
.nav-bar__row {
    display: flex;
    align-items: center;
    height: 100%
}
.nav-bar__logo svg {
    display: block;
    fill: $nav-bar-logo-color;
}
.nav-bar__departments {
    flex-shrink: 0;
}
.nav-bar__indicators {
    display: flex;
    height: 100%;

    @include direction {
        #{$margin-inline-start}: auto;
    }
}


@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .nav-bar__nav-links {
        @include direction {
            #{$margin-inline-start}: 14px;
            #{$margin-inline-end}: 14px;
        }
    }
}
