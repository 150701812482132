@media (min-width: 3001px) and (max-width: 5200px) {

    .contact-section {
        align-items: center;
    }

    .contact-box {
        width: 1920px;
    }

    .contact-display-contnet {
        display: grid;
        grid-template-columns: 60% 37%;
        padding: 0 4% !important;
    }

    .contact-map {
        width: 100%;

        iframe {
            width: 100%;
        }
    }

    .contact-bottom-info {
        gap: 60px;
        justify-content: flex-start;
    }
}

@media (min-width: 2000px) and (max-width: 3000px) {
    .contact-display-contnet {
        display: grid;
        grid-template-columns: 60% 37%;
        padding: 0 6% !important;
    }

    .contact-map {
        width: 100%;

        iframe {
            width: 100%;
        }
    }

    .contact-bottom-info {
        gap: 30px;
        justify-content: flex-start;
    }
}

@media (min-width: 1801px) and (max-width: 1862px)  {
    .contact-display-contnet {
        padding: 0 7%;
    }

    .grid-bottom .grid-address {
        max-width: 180px;
    }
}

@media (min-width: 1701px) and (max-width: 1800px) {

    .grid-bottom .grid-address {
        max-width: 160px;
    }

    .contact-display-contnet {
        padding: 0 7%;
    }

    .contact-map iframe {
        width: 425px;
    }

    .contact-map-skeleton {
        width: 425px;
    }

    .grid-email {
        max-width: 190px;
    }

    .grid-address {
        max-width: 155px;
    }
}

@media (min-width: 1200px) and (max-width: 1350px) {
    .contact-section {
        margin: 0 5% !important;

        .contact-box {
            height: 720px !important;
        }

        .contact-display-contnet {
            padding: 0 5% !important;
            gap: 30px !important;

            .contact-form {
                .contact-header {
                    flex-wrap: wrap;
                    font-size: 40px;

                    h1 {
                        font-size: 40px;
                    }
                }
            }

            .contact-map {
                width: 420px !important;
                height: 560px !important;

                iframe {
                    width: 420px !important;
                    height: 560px !important;
                }
            }
        }

        .contact-bottom-info {
            flex-wrap: nowrap;
            gap: 10px;
        }

        .grid-bottom .grid-address {
            max-width: 160px;
        }
    }
}

@media (min-width: 1601px) and (max-width: 1660px) {
    .contact-section {
        margin: 0 11.5%;
    }
    
}


@media (min-width: 1551px) and (max-width: 1600px) {
    .contact-section {
        margin: 0 11%;
        .contact-box {
            height: 720px !important;
        }
        .grid-bottom .grid-address {
            max-width: 220px;
        }
        .contact-display-contnet {
            padding: 0 6% !important;
        }
    }
    
}

@media (min-width: 1501px) and (max-width: 1550px) {
    .contact-section {
        margin: 0 8%;
        .contact-box {
            height: 720px !important;
        }
        .grid-bottom .grid-address {
            max-width: 220px;
        }
        .contact-display-contnet {
            padding: 0 6% !important;
        }
    }
    
}

@media (min-width: 1451px) and (max-width: 1500px) {
    .contact-section {
        margin: 0 8%;
        .contact-box {
            height: 720px !important;
        }
        .grid-bottom .grid-address {
            max-width: 200px;
        }
        .contact-display-contnet {
            padding: 0 5% !important;
        }
    }
    
}

@media (min-width: 1401px) and (max-width: 1450px) {
    .contact-section {
        margin: 0 7%;
        .contact-box {
            height: 720px !important;
        }
        .grid-bottom .grid-address {
            max-width: 200px;
        }
        .contact-display-contnet {
            padding: 0 5% !important;
        }
    }
    
}

@media (min-width: 1351px) and (max-width: 1400px) {
    .contact-section {
        margin: 0 6%;
        .contact-box {
            height: 700px !important;
        }
        .grid-bottom .grid-address {
            max-width: 160px;
        }
        .contact-display-contnet {
            padding: 0 5% !important;
        }
    }
    
}


@media (min-width: 1351px) and (max-width: 1550px) {
    .contact-section {
       

        .contact-display-contnet {
            gap: 30px !important;

            .contact-form {
                .contact-header {
                    flex-wrap: wrap;
                    font-size: 40px;

                    h1 {
                        font-size: 40px;
                    }
                }
            }

            .contact-map {
                width: 420px !important;
                // height: 560px !important;

                iframe {
                    width: 420px !important;
                    // height: 560px !important;
                }
            }
        }

        .contact-bottom-info {
            flex-wrap: nowrap;
            gap: 10px;
        }

        
    }
}

@media (min-width: 1551px) and (max-width: 1700px) {
    .contact-section {
        .contact-box {
            height: 720px !important;
        }

        .contact-display-contnet {
            padding: 0 6% !important;

            .contact-form {
                .contact-header {
                    flex-wrap: wrap;
                    font-size: 40px;

                    h1 {
                        font-size: 40px;
                    }
                }
            }

            .contact-map {
                width: 420px !important;
                // height: 560px !important;

                iframe {
                    width: 420px !important;
                    // height: 560px !important;
                }
            }
        }

        .contact-bottom-info {
            flex-wrap: nowrap;
            gap: 10px;

            .grid-address {
                max-width: 160px;
            }
        }
    }
}


// mobile section
@media (min-width: 992px) and (max-width: 1199px) {
    .contact-section {
        margin: 0 5% !important;

        .contact-box {
            height: 670px !important;
        }

        .contact-display-contnet {
            padding: 0 5% !important;
            gap: 30px !important;

            .contact-form {
                .contact-header {
                    flex-wrap: wrap;
                    font-size: 40px;

                    h1 {
                        font-size: 40px;
                    }
                }
            }

            .contact-map {
                width: 380px !important;
                height: 540px !important;

                iframe {
                    width: 380px !important;
                    height: 540px !important;
                }
            }
        }

        .contact-bottom-info {
            flex-wrap: nowrap;
            gap: 10px;

            .grid-info,
            .grid-title,
            a {
                font-size: 12px !important;
            }
        }
        .grid-bottom .grid-address {
            max-width: 145px;
        }
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        height: 0.6rem !important;
    }

    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        transform: translate(14px, 11px) scale(1) !important;
    }
}

@media (min-width: 880px) and (max-width: 991px) {
    .contact-section {
        margin: 0 5% !important;

        .contact-box {
            height: 660px !important;
        }

        .contact-display-contnet {
            padding: 0 5% !important;
            gap: 30px !important;

            .contact-form {
                .contact-header {
                    flex-wrap: wrap;
                    font-size: 40px;

                    h1 {
                        font-size: 40px;
                    }
                }
            }

            .contact-map {
                width: 300px !important;
                height: 470px !important;

                iframe {
                    width: 300px !important;
                    height: 470px !important;
                }
            }
        }

        .contact-bottom-info {
            flex-wrap: nowrap;
            grid-area: 10px;

            .grid-info,
            .grid-title,
            a {
                font-size: 12px !important;
                word-break: break-word;
            }
        }
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        height: 0.6rem !important;
    }

    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        transform: translate(14px, 11px) scale(1) !important;
    }
}

@media (min-width: 576px) and (max-width: 879px) {
    .contact-section {
        margin: 0 5% !important;
        padding-top: 40px !important;
        padding-bottom: 60px !important;

        .contact-display-contnet {
            position: relative !important;
            flex-direction: column;
            // padding: 0 !important;
            padding: $page-mobile $page-mobile !important;
            justify-content: center !important;

            .contact-form {
                .contact-header {
                    flex-wrap: wrap;
                    font-size: 42px;

                    h1 {
                        font-size: 42px;
                    }
                }
            }

            .contact-map {
                width: 100% !important;
                height: 350px !important;
                // margin:  0 $page-mobile !important;

                iframe {
                    width: 100% !important;
                    height: 350px !important;
                }
            }
        }

        .contact-bottom-info {
            gap: 20px;
            flex-wrap: nowrap !important;
        }
    }

    .contact-box {
        height: 100% !important;
        grid-template-columns: auto !important;
    }
}

@media (max-width: breakpoint(md-end)) {
    .contact-box {
        box-shadow: rgba(17, 17, 26, 0.02) 0px 4px 16px, rgba(17, 17, 26, 0.02) 0px 8px 32px;
    }
}



@media (min-width: 280px) and (max-width: 575px) {
    .contact-section {
        margin: 0 5% !important;
        padding-top: 40px !important;
        padding-bottom: 60px !important;

        .contact-display-contnet {
            position: relative !important;
            flex-direction: column;
            // padding: 0 !important;
            padding: $page-mobile $page-mobile !important;
            justify-content: center !important;

            .contact-form {
                .contact-header {
                    flex-wrap: wrap;
                    font-size: 42px;

                    h1 {
                        font-size: 42px;
                    }
                }
            }

            .contact-map {
                width: 100% !important;
                height: 350px !important;
                // margin:  0 $page-mobile !important;

                iframe {
                    width: 100% !important;
                    height: 350px !important;
                }
            }
        }

        .contact-bottom-info {
            gap: 20px;
            flex-direction: column !important;
        }
    }

    .contact-box {
        height: 100% !important;
        grid-template-columns: auto !important;
    }

    .captcha-box {
        .form-group {
            transform: scale(0.77);
            transform-origin: 0 0;
        }
    }

    .captcha-section {
        overflow: hidden;
    }
}