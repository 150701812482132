/*
// .nav-links
*/
@use 'sass:math';
@import '../variables';
@import '../functions';
@import '../mixins/direction';

$item-padding: 25px;

.nav-links__list {
    height: inherit;
    z-index: 10;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 34px;
}

.nav-links__item {
    &>a {
        font-size: 18px;
        line-height: 1;
        letter-spacing: .02em;
        font-weight: $font-weight-thin;
        color: $nav-links-item-default-font-color;

        span {
            background: $nav-links-item-default-bg;
            box-shadow: $nav-links-item-default-shadow;
        }

        svg {
            fill: $nav-links-item-default-arrow-color;
        }

        &>span {
            display: flex;
            align-items: center;
            background: transparent;
            border-radius: $nav-links-item-border-radius;
        }
    }

    &:hover>a {
        color: $nav-links-item-hover-font-color;

        span {
            background: $nav-links-item-hover-bg;
            box-shadow: $nav-links-item-hover-shadow;
        }

        svg {
            fill: $nav-links-item-hover-arrow-color;
        }

        &>span {
            transition-duration: .1s;
        }
    }

    &:hover .nav-links__menu,
    &:hover .nav-links__megamenu {
        opacity: 1;
        visibility: visible;
        transform: rotateX(0deg);
        position: absolute;
        // width: 100%;
        // right: 0;
    }
}

.nav-links__item {
    .active {
        color: $accent-color;
    }

    a {
        &:focus,
        &:focus-visible,
        &:focus-within,
        &:active {
            color: $accent-color;
        }
    }

}

.default-nav .mega-colums {
    width: 100%;
    padding: 25px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
}

.nav-links__item--menu {
    position: relative;
}

.nav-links__item--with-submenu {
    &>a span {
        position: relative;

        // @include direction {
        //     #{$padding-inline-end}: ($item-padding + 4px + 9px);
        // }
    }

    &>a svg {
        top: calc(50% - 3px);
        position: absolute;

        @include direction {
            #{$inset-inline-end}: $item-padding - 2px;
        }
    }
}

.megamenu_menu {
    width: 120vh !important;
}

.nav-links__menu,
.nav-links__megamenu {

    top: 100%;
    z-index: 10;
    position: absolute;
    visibility: hidden;
    transform-origin: top;
    transform: rotateX(45deg);
    opacity: 0;
    transition: transform .2s, opacity .2s;
}

.nav-links__megamenu {
    @include direction {
        #{$inset-inline-start}: 0;
    }
}


.nav-links__megamenu--size--xs {
    width: math.div(2, 12) * 100%;
}

.nav-links__megamenu--size--sm {
    width: math.div(4, 12) * 100%;
}

.nav-links__megamenu--size--nl {
    width: 50%;
}

.nav-links__megamenu--size--md {
    width: math.div(8, 12) * 100%;
}

.nav-links__megamenu--size--lg {
    width: math.div(10, 12) * 100%;
}

.nav-links__megamenu--size--xl {
    width: 100%;
}


@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    $item-padding: 10px;

    .nav-links__item>a>span {
        padding: 0 $item-padding;
    }

    .nav-links__item--with-submenu>a>span {
        @include direction {
            #{$padding-inline-end}: ($item-padding + 4px + 9px);
        }
    }

    .nav-links__item--with-submenu>a svg {
        @include direction {
            #{$inset-inline-end}: $item-padding - 2px;
        }
    }
}