.Slider-Section {
    height: 700px;
    overflow: hidden;

    .Slider-Slick {
        height: 100%;
        overflow: hidden;
    }

    .Slider-img {

        .slider-bg {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            filter: blur(3.5px);
        }

        .blur {
            object-fit: cover;
            background-size: cover !important;
            background-position: center;
            img {
                object-fit: cover;
                background-size: cover;
            }
        }
    }

    .Slider-home,
    .Slider-img,
    .lazy-load-image-background {
        width: 100%;
        height: 700px;
        overflow: hidden;

        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            filter: blur(3.5px);
            -webkit-filter: blur(3.5px);
        }
    }

    .overlay {
        position: absolute;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
        width: 100%;
        height: 100%;
        top: 0;
        height: 700px;
    }


    .Slider-home {
        display: flex;
        align-items: center;
        justify-content: center;

        .Slider-Caption {
            max-width: 60vw;
            text-align: center;
            position: absolute;
            z-index: 5;
            gap: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            
            .caption-title {
                color: $light-color;
                font-size: 64px;
                line-height: 64px;
                font-weight: $font-weight-bold;
            }
            .caption-text {
                color: rgba(255, 255, 255, 0.8);
                width: 70%;
                text-align: center;
                font-size: 32px;
                margin: 0;
                font-weight: $font-weight-semi-bold;
            }
        }
    }

}