@media (min-width: 1601px) and (max-width: 1800px) {
    .nav-bar__container {
        gap: 160px;
    }
}

// mobile section

@media (max-width: breakpoint(lg-end)) {
    //code
}
@media (max-width: breakpoint(md-end)) {
    .mobile-header__menu-button{
        fill: black;
    }
    .mobile-header__menu-button:hover{
        fill: black;
    }
    .mobile-header__panel{
        background: #fff;
    }
}
@media (max-width: breakpoint(sm-end)) {
    .mobile-header__menu-button{
        fill: black;
    }
    .mobile-header__menu-button:hover{
        fill: black;
    }
    .mobile-header__panel{
        background: #fff;
    }
}
@media (max-width: breakpoint(xs-end)) {
    .mobile-header__menu-button{
        fill: black;
    }
    .mobile-header__menu-button:hover{
        fill: black;
    }
    .mobile-header__panel{
        background: #fff;
    }
}