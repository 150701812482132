.Services-section {
    overflow: hidden;
    // background: url(../../img/services/services-bg.png);
}

.Services-body {
    display: flex;
    flex-direction: column;

    .Page-header-section {
        margin: 0px 23%;
        margin-bottom: 100px;
    }
}

.services-top {
    background: url(../../img/services/services-bg.png);
}

.services-bottom {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)), url(../../img/services/services-bg.png);
}

.Services-content,
.Services-content-grid {
    margin: 0 10.5%;
}

.Services-content {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.Services-section {
    .services-boxes {
        display: grid;
        gap: 50px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin: 0px 10.5%;
        margin-top: 100px;
        margin-bottom: 10px;

        .content-box {
            padding: 30px;
            // background: #f9f9f9;
            // border-radius: 15px;
            // border: 1px solid #e0e0e0;
            background: #FDFDFD;
            box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
            // box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
            // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
            // box-shadow: rgba(28, 98, 158, 0.4) 5px 5px, rgba(28, 98, 158, 0.3) 10px 10px, rgba(28, 98, 158, 0.2) 15px 15px, rgba(28, 98, 158, 0.1) 20px 20px, rgba(28, 98, 158, 0.05) 25px 25px;
            // background: #ffffff;
            border-radius: 5px;
 
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
        }

        .content-header {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .content-logo {
            width: 60px;
            height: 60px;
            object-fit: contain;
        }
    }
}

.Services-content-grid {
    // margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 60px;

    .card-left {
        display: flex;
        flex-direction: column;
        max-width: 580px;

        .card-textContent {
            margin: 0;
            margin-top: 35px;
        }
    }



    .card-right {
        flex-direction: column-reverse;
        max-width: 580px;

        .card-textContent {
            margin: 0;
            margin-top: 35px;
        }
    }
}