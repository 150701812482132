.Portfolio-section {
    margin: 0 10.5%;
    display: flex;
    position: relative;
    overflow: hidden;
    gap: 30px;
    flex-direction: column;
    
    .Page-header-section {
        margin-bottom: 0;
    }

    .projects-boxes {
        margin-bottom: 0;
    }
}


.animation-img-bg {
    .animation-img {
        position: absolute;
        top: 100px;
        width: 760px;
    }
}