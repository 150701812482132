.aboutus-top {
    height: 400px;
    display: flex;
    position: relative;
    overflow: hidden;

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .overlay {
        position: absolute;
        background: linear-gradient(0deg, rgba(0, 62, 124, 0.7), rgba(0, 62, 124, 0.7));
        width: 100%;
        height: 100%;
        top: 0;
        height: 400px;
    }

    .footer-parallax-title {
        margin: 0;
    }

    .photo-menu-aboutus {

        // background-attachment: fixed;
        width: 100%;
        // position: relative;
        display: flex;
        flex-direction: row;

    }

    .about-us-bg {
        background: url(../../img/mission/mission-vision.png);
        filter: blur(2.5px);
        -webkit-filter: blur(2.5px);
        background-size: cover;
        width: 100%;
        // background-attachment: fixed;
        // position: relative;
        background-position: center;
    }

    .about-feature {
        position: relative;
        width: 100%;
        max-width: 100%;
        z-index: 10;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        // flex-direction: row;
        height: 100%;
        justify-content: center;
        gap: 25px;
    }

    .about-feature h1 {
        color: $light-color;
        margin: 0;
    }

    .about-feature p {
        color: $light-color;
        margin: 0;
        font-weight: $font-weight-bold;
    }

    .aboutus-mission {
        display: flex;
        height: 100%;
        position: absolute;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 10.5%;
        gap: 190px;
    }

}