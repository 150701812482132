@import '../variables';


@mixin card() {
    border: $card-border;
    border-radius: $card-border-radius;
}

.card-left {
    display: flex;

    .card-textContent {
        margin-left: 50px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        grid-gap: 15px;
        gap: 15px;
        justify-content: center;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .card-title {
        font-size: 24px;
        font-weight: $font-weight-bold;
    }

    .card-text {
        font-size: 18px;

    }
}

.card-image {
    img {
        width: 580px;
        height: 340px;
        object-fit: cover;
        box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
        // filter: drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.1));
        border-radius: 5px;
    }
}

.card-right {
    display: flex;

    .card-textContent {
        margin-right: 50px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        grid-gap: 15px;
        gap: 15px;
        justify-content: center;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .card-title {
        font-size: 24px;
        font-weight: $font-weight-bold;
    }

    .card-text {
        font-size: 18px;

    }
}