/*
// .site-header
*/
@import '../variables';
@import '../functions';
@import '../mixins/direction';


.site-header {
    position: relative;
    z-index: 99;
    background: $header-bg;
    color: $header-font-color;
}
.site-header__middle {
    display: flex;
    border-bottom: $topbar-border-bottom;
    align-items: center;
    height: 54px;
    justify-content: flex-end;
}

.site-header__bottom {
    display: flex;
    align-items: center;
    background-color:  $nav-bar-bottom-background;
    position: absolute;
    right: 0;
    width: 100%;
    height: 100px;
    justify-content: flex-end;
}

.site-header__bottom_relative {
    display: flex;
    align-items: center;
    background-color:  $nav-bar-bottom-background;
    position: relative;
    right: 0;
    width: 100%;
    height: 100px;
    justify-content: flex-end;
}

.site-nav-bottom {
    margin: 0 5%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.site-nav-topbar {
    margin: 0 5%;
    display: flex;
    align-items: center;
}

.site-header__search {
    width: 100vh;
}
.site-header__phone {
    line-height: 1;
    flex-shrink: 0;

    @include direction {
        text-align: $inline-end;
        #{$margin-inline-start}: 80px;
    }
}
.site-header__phone-title {
    font-size: 14px;
    color: $header-font-muted-color;
    margin-bottom: 6px;
}
.site-header__phone-number {
    font-size: 20px;
    font-weight: $font-weight-bold;
}
.site-header__nav-bar {
    height: $nav-bar-height;
}


@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .site-header__logoscroll {
        width: 210px;
        justify-content: flex-start;

        @include direction {
            #{$margin-inline-end}: 24px;
        }
    }
}

.search__icone, .search-plus {
    color: $light-color;
    font-weight: $font-weight-semi-bold;
    fill: $header-search-input-default-icon-color;

    &:hover,
    &:focus {
        outline: none;
        fill: $header-search-input-default-icon-hover-color;
    }
}
