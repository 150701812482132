@media (min-width: 1500px) and (max-width: 1600px) {
    .news-header {
        gap: 30px;
    }

    .news-title {
        font-size: 22px;
    }

    .news {
        .btn-pages {
            font-size: 18px;
        }

        .news-text {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 1400px) and (max-width: 1499px) {
    .news-header {
        gap: 50px;
    }

    .news-title {
        font-size: 19px;
    }

    .news {
        .btn-pages {
            font-size: 18px;
        }

        .news-date {
            font-size: 18px;
            line-height: 1.5rem;
        }
    }

    .news-text {
        margin-bottom: 5px;
        font-size: 16px;
    }

    .page-content-half {
        .news-text {
            font-size: 18px !important;
        }
    }

    .news-textContent {
        gap: 0px;
    }

    .news-galeryDetails {
        padding: 0 10%;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {

    .news-img img {
        width: 450px;
    }

    .news-textContent {
        gap: 0px;
        justify-content: space-between;
    }

    .news-text {
        font-size: 16px;
    }

    .news-title {
        font-size: 18px;
    }

    .news {
        .btn-pages {
            font-size: 16px;
        }

        .news-date {
            font-size: 16px;
            line-height: 1.3rem;
        }
    }


    .page-content-half {
        .news-text {
            font-size: 18px !important;
        }
    }

    .news-header {
        gap: 50px;
    }

    .news-galeryDetails {
        padding: 0 5%;
    }

}

@media (min-width: 992px) and (max-width: 1199px) {

    .news-textContent {
        gap: 0px;
        justify-content: space-evenly;
    }

    .news-text {
        font-size: 16px;
        line-height: 1.5rem;
    }

    .news-title {
        font-size: 18px;
    }

    .news {
        .btn-pages {
            font-size: 16px;
        }

        .news-date {
            font-size: 16px;
            line-height: 1.3rem;
        }
    }


    .page-content-half {
        .news-text {
            font-size: 18px !important;
        }
    }

    .news-header {
        gap: 50px;
    }
}

@media (min-width: 992px) and (max-width: 1087px) {
    .news-text {
        margin: 8px 0px !important;
    }
}

@media (min-width: 992px) and (max-width: 2400px) {
    .news {
        height: 317px !important;
    }
}

@media (max-width: breakpoint(lg-end)) {
    .news-textContent {
        padding: 25px;
        gap: 0px;

    }

    .news-header {
        gap: 50px;
    }

    .news-text {
        font-size: 14px;
    }

    .news-img img {
        width: 400px;
    }

    .news-galeryDetails {
        padding: 0 5%;
    }
}

@media (max-width: breakpoint(md-end)) {
    .news {
        flex-direction: column !important;
    }

    .news-img img {
        width: 100% !important;
    }

    .news-textContent {
        padding: 24px !important;
    }

    .news-header {
        gap: 0px !important;

        span {
            height: 220px;
        }
    }

    .news-galeryDetails {
        padding: 0;
    }

}

@media (max-width: breakpoint(sm-end)) {
    .news-Header {
        align-items: start !important;
        flex-direction: column !important;
        gap: 10px;
    }

    .news-galeryDetails {
        padding: 0;
    }
    

    .category-form {

        .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
        .MuiSelect-select,
        .MuiSelect-outlined {
            padding: 7.5px 16px;
        }
    }

    .news-Main {
        gap: 50px;
    }

    .news-header {
        flex-direction: column !important;
        justify-content: none !important;
    }
}

@media (max-width: breakpoint(xs-end)) {}