
@media (min-width: 730px) and (max-width: 807px){
    .aboutbox{
        width: 260px;
    }
    .aboutBoxes-main{
        flex-wrap: wrap !important;
        align-items: center !important;
        flex-direction: row !important;
    }
}

@media (min-width: 620px) and (max-width: 729px){
    .aboutbox{
        width: 380px;
    }
}


@media (min-width: 500px) and (max-width: 619px){
    .aboutbox{
        width: 360px;
    }
}
@media (min-width: 280px) and (max-width: 380px){
    .aboutbox{
        width: 100%;
    }
}

@media (max-width: breakpoint(lg-end)) {

}

@media (max-width: breakpoint(md-end)) {
    .aboutBoxes-main {
        flex-wrap: wrap !important;
        align-items: center !important;
     }
}
@media (max-width: breakpoint(sm-end)){
    .aboutBoxes-main {
        flex-direction: column;
        align-items: center !important;
     }
}
@media (max-width: breakpoint(xs-end)) {
    .aboutBoxes-main {
        flex-direction: column !important;
        align-items: center !important;
     }
}