/*
// .product-gallery
*/
@import '../variables';
@import '../mixins/direction';


.product-gallery {}

.product-gallery__featured {
    margin-top: $block-margin-top;
    position: relative;
    box-shadow: $product-gallery-item-default-shadow;
    padding: 2px;
    border-radius: $product-gallery-item-border-radius;

    // .product-image {
    //     padding: 22px 20px 18px 20px;
    // }
    .product-image__body:focus {
        outline: none;
    }
}

.product-gallery__zoom {
    position: absolute;
    cursor: pointer;
    padding: 11px;
    border: none;
    z-index: 2;
    top: 0;
    background: $product-gallery-zoom-button-normal-bg-color;
    fill: $product-gallery-zoom-button-normal-icon-color;
    transition:
        background .2s,
        fill .2s;

    @include direction {
        #{$inset-inline-end}: 0;
        #{$border-start-end-radius}: $product-gallery-item-border-radius + .5px;
        #{$border-end-start-radius}: 2px;
        transform: scaleX($transform-direction);
    }

    &:focus {
        outline: none;
    }

    &:hover {
        background: $product-gallery-zoom-button-hover-bg-color;
        fill: $product-gallery-zoom-button-hover-icon-color;
    }

    &:active {
        background: $product-gallery-zoom-button-active-bg-color;
        fill: $product-gallery-zoom-button-active-icon-color;
    }
}

.product-gallery__carousel {
    overflow: hidden;
    margin-top: 10px;

    .slick-list {
        margin: 0 -5px;
    }

    .slick-slide {
        padding: 0 5px;
    }

    .slick-track {
        @include direction {
            #{$margin-inline-start}: 0;
        }
    }
}

.product-gallery__carousel-item {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    margin-left: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    box-shadow: $product-gallery-item-default-shadow;
    cursor: pointer;
    display: block;
    // box-shadow: $product-gallery-item-default-shadow;
    // padding: 12px;
    border-radius: $product-gallery-item-border-radius;
    border: none;
    background: transparent;

    &:focus {
        outline: none;
    }
}

.product-gallery__carousel-item--active {
    box-shadow: $product-gallery-item-active-shadow;
}


.product-gallery__carousel-item {

    .lazy-load-image-loaded,
    .lazy-load-image-background {
        display: block !important;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: -1;

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
            min-height: 100% !important;
            z-index: -1;
            position: absolute;
            border-radius: 3px;
            cursor: pointer;
        }
    }
}

.product-gallery__carousel-item--active {

    .lazy-load-image-loaded,
    .lazy-load-image-background {
        display: block !important;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: -1;

        img {
            width: 100%;
            object-fit: contain;
            z-index: -1;
            min-height: 100% !important;
            position: absolute;
            height: auto;
            border-radius: 3px;
            cursor: pointer;
        }

    }
}