.Services-home-section {

    .content-grid {
        gap: 140px;
        display: grid;
        grid-template-columns: 31.5% 59.2%;
        margin: 0 10.5%;
    }

    .services-description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        justify-content: space-between;

        .services-title {
            font-size: 48px;
            font-weight: $font-weight-bold;
        }

        .services-text {
            font-size: 16px;
            font-weight: $font-weight-normal;
            color: $text-color-light;
            // line-height: 30px;
            // letter-spacing: -0.015em;
        }
    }

    .services-boxes {
        display: grid;
        padding-top: 10px;
        word-break: break-word;
        gap: 50px;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        .content-header {
            display: flex;
            flex-direction: row;
            gap: 30px;
            align-items: center;
            
            img {
                height: 57px;
                object-fit: contain;
                width: 57px;
            }

            .content-title {
                margin: 0;
            }
        }

        .content-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            justify-content: space-between;

            .contnet-text {
                margin: 0;
                // line-height: 30px;
                color: $text-color-light;
            }
        }

        .btn-pages {
            font-size: 18px;
        }
       
    }

}