.AboutUs-home-section {
    background-color: #F5F5F5;

    .content-grid {
        gap: 140px;
        display: grid;
        grid-template-columns: auto auto;
        margin: 0 10.5%;
        padding-top: 75px;
        padding-bottom: 75px;
    }


    .about-text {
        animation-fill-mode: both;
        animation-duration: 1000ms;
        animation-delay: 0ms;
        animation-iteration-count: 1;
        opacity: 1;
        animation-name: react-reveal-567854384270007-1;
    }

    .about-sub-title {
        color: $accent-color;
        font-weight: $font-weight-normal;
        font-size: 18px;
    }

    .about-title {
        text-transform: uppercase;
        font-size: 32px;
        font-weight: $font-weight-bold;
    }

    .logo-animation-section {
        position: relative;
        width: 658px;
        height: 587px;

        .content-img {
            z-index: 3;
            position: relative;
        }

        .animation-img {
            position: absolute;
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.about-us-description {
    display: flex;
    flex-direction: column;
    // fix the main width later
    // min-width: 705px;
    justify-content: center;
    gap: 20px;

    .toggle-text {
        display: flex;
        padding-bottom: 10px;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;

        .toggle-title {
            outline: none;
            color: $light-opposite-color;
            border: none;
            background: transparent;
            padding: 0;
        }

        .toggle-title-active {
            outline: none;
            border: none;
            background: transparent;
            padding: 0;
            color: $accent-color;

            .link-line {
                width: 100%;
            }
        }

        .toggle-buttons {
            display: flex;
            flex-direction: row;
            gap: 30px;
        }

        .toggle-text-show {
            display: flex;
            gap: 20px;
            flex-direction: column;
            align-items: flex-start;

            .btn-default {
                font-size: 16px;
                font-weight: $font-weight-semi-bold;
                padding: 8px 26px;
            }
        }
    }
}