@media (min-width: 1800px) and (max-width: 2400px) {
    .Services-section {
        .card-image img {
            width: 670px;
            height: 390px;
        }

        .Services-content-grid {

            .card-left,
            .card-right {
                max-width: 670px;
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1300px) {
    .Services-home-section .content-grid {
        gap: 80px !important;
    }

    .Services-home-section {
        .services-boxes {
            gap: 50px !important;
            word-break: break-word;
            grid-template-columns: 45.5% 45.5% !important;
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .Services-home-section .content-grid {
        grid-template-columns: auto;
        gap: 60px !important;
    }

    .Services-home-section .services-boxes {
        grid-template-columns: auto auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .Services-home-section .content-grid {
        grid-template-columns: auto;
        gap: 60px !important;
    }

    .Services-home-section .services-boxes {
        grid-template-columns: auto auto;
    }
}

@media (min-width: 576px) and (max-width: 767px) {

    .Services-home-section {
        .services-boxes {
            padding: 0;
            justify-items: center
        }
        .content-box {
            text-align: center;
            background: #FDFDFD;
            // box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
            box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
            padding: 30px;
            border-radius: 5px;
            width: 100%;
            max-width: 460px;
            align-items: center !important;

            .content-header {
                flex-direction: column;
            }
        }

        .services-description {
            text-align: center;
            align-items: center !important;
        }
    }

    .Services-home-section .content-grid {
        grid-template-columns: auto;
        gap: 60px !important;
    }

    .Services-home-section .services-boxes {
        grid-template-columns: auto;
        padding-bottom: 60px;
    }

    .Services-home-section .services-boxes .content-header .content-title {
        font-size: 24px !important;
    }
}

@media (min-width: 280px) and (max-width: 575px) {

    .Services-home-section {
        .services-boxes {
            padding: 0;
        }
        .content-box {
            text-align: center;
            background: #FDFDFD;
            // box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
            box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
            padding: 30px;
            border-radius: 5px;
            align-items: center !important;

            .content-header {
                flex-direction: column;
            }
        }

        .services-description {
            text-align: center;
            align-items: center !important;
        }
    }

    .Services-home-section .content-grid {
        grid-template-columns: auto;
        gap: 50px !important;
    }

    .Services-home-section .services-boxes {
        grid-template-columns: auto;
        padding-bottom: 40px;
    }

    .Services-home-section .services-boxes .content-header .content-title {
        font-size: 24px;
    }
}