.projects-boxes {
    display: grid;
    // flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
    grid-template-columns: 30% 30% 30%;
    margin-bottom: 50px;
    padding-left: 0px !important;
}

.projects-box-link {
    height: 100%;
    width: 100%;
}


.projects-box-link {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    // box-shadow: 10px 10px 15px rgb(0 0 0 / 10%);
    // overflow: hidden;
    align-items: flex-end;
    transition: 0.4s ease-out;

    &:hover {
        transform: translateY(-20px);

        &:before {
            opacity: 1 !important;
        }

        .info {
            // visibility: visible;
            opacity: 1 !important;
            transform: translateY(0px);
        }
    }


    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background: rgba(black, 0.6);
        z-index: 2;
        transition: 0.5s;
        opacity: 0;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: 10px 10px 15px rgb(0 0 0 / 10%);
        // position: absolute;
        top: 0;
        left: 0;
    }


    .info {
        position: absolute;
        z-index: 3;
        color: white;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        // padding: 20px;
        // height: 100%;
        // overflow: hidden;
        // visibility: hidden;
        opacity: 0 !important;
        transform: translateY(30px);
        transition: 0.5s !important;

        h1 {
            margin: 0px;
            padding: 20px;
            color: $light-color;
            font-size: 28px;
        }
    }

}

.projects-box-link img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.buttoni-bottom {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

.projects-slideshow__project-content {
    margin: 100px 10.5%;

    .Page-header-section {
        margin: 0 9%;
        margin-bottom: 50px;
        // margin-bottom: 30px;
    }

}