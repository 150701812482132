.newsContainer {
    margin: 0 10.5%;
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
}

.page-link {
    height: 40px;
}

.news-header {
    gap: 30px;
}

.news-img {
    .lazy-load-image-background.blur {
        height: 100%;
        filter: blur(0px);
        -webkit-filter: blur(0px);
    }
}

.news-Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .news-header-title {
        font-size: 36px;
        font-weight: $font-weight-normal;
    }

    select {
        width: 216px;
        border-color: #D9D9D9;
        height: 39px;

        &:focus-visible {
            outline: none;
        }

    }

    .select {
        border-color: #D9D9D9;
        height: 39px;

        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
            top: -8px;
        }

        .css-6ffsbr-MuiAutocomplete-root .MuiOutlinedInput-root {
            padding: 0px;
        }

        &:focus-visible {
            outline: none;
        }

    }

    .css-6ffsbr-MuiAutocomplete-root .MuiOutlinedInput-root {
        padding: 0px !important;
        /* padding: 9px; */
    }
}

.news-Main {
    display: flex;
    flex-direction: column;
    grid-gap: 66px;
    gap: 66px;
}

.news-section {
    .css-wjh20t-MuiPagination-ul {
        justify-content: center;
    }
}

.news {
    display: flex;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
    height: 100%;
    background-color: #FAFAFA;
}

.news-img {

    .lazy-load-image-loaded {
        height: 100%;
        object-fit: cover;
    }

    img {
        width: 555px;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
    }
}

.news-textContent {
    padding: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    justify-content: center;
}

.news-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.news-textContent {
    .news-text {
        margin-bottom: 0;
    }
}

.news-galeryDetails {
    padding: 0 15%;
}

.news-title {
    font-size: 24px;
    font-weight: $font-weight-bold;
}

.news-date {
    font-size: 20px;
    font-weight: $font-weight-medium;
    color: #848484;
}

.news-text {
    font-size: 18px;
}

.category-form {
    width: 216px;

    .css-14lo706 {
        display: none;
    }
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-notchedOutline,
    .css-igs3ac {
        top: 0;
        border-color: #d9d9d9 !important;
        border-radius: 0.2rem;
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
    .MuiSelect-select,
    .MuiSelect-outlined {
        padding: 10.5px 16px;
    }
}

.category-select {
    border: none;
}

.pswp__img {
    object-fit: contain;
}

.page-content-half {
    .page-title-half {
        font-size: 34px;
    }

    .news-text {
        font-size: 18px;
        margin: 30px 0;
        font-weight: $font-weight-medium;
        margin-bottom: 13px;
    }

    p {
        margin-bottom: 0.8rem;
    }

    .page-text {
        margin-top: 0;
    }
}


.readMoreButton {
    display: flex;
    align-self: start;
}