.Trainings-section {
    padding-top: 0;

    .Slider-Section {
        height: 380px;

        .Slider-home {
            height: 380px;
        }

        .Slider-img {
            height: 380px;
        }

        .slider-bg {
            height: 380px;
        }

        .Slider-Caption {
            gap: 10px;

            .caption-title {
                font-size: 48px;
                margin: 0;
            }

            .caption-text {
                font-size: 36px;
                line-height: 2.2rem;
            }
        }

    }

    .course-slider {
        padding: 100px 0;
        background-repeat: no-repeat;
        background-size: cover;
        height: 380px;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Page-header-section {
        position: relative;
        color: $light-color;
        font-weight: $font-weight-semi-bold;
        gap: 10px;

        .Page-title {
            font-weight: $font-weight-semi-bold;
        }
    }

    .overlay {
        position: absolute;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        width: 100%;
        height: 100%;
        // top: 90px;
        // filter: blur(3.5px);
        height: 380px;
    }

    .course-text-title {
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: center;

        span {
            color: rgba(255, 255, 255, 0.8);
            font-weight: $font-weight-medium;
            font-size: 20px;
        }

        .Page-sub-title {
            margin: 0;
        }
    }

    .course-about {
        margin: 100px 10.5%;
    }

    .content-grid {
        gap: 140px;
        display: grid;
        grid-template-columns: auto auto;
        margin-top: 30px;

        .course-description {
            display: flex;
            grid-gap: 30px;
            gap: 30px;
            flex-direction: column;
        }

        .course-text {
            gap: 30px;
            display: flex;

            .course-content {
                display: flex;
                flex-direction: column;
            }

            .course-paragraph {
                span {
                    font-weight: $font-weight-semi-bold;
                }

                // p {
                //     margin-bottom: 0;
                // }
            }

            .course-button {
                display: flex;
                gap: 10px;
                flex-direction: column;
                justify-content: center;

                p {
                    margin: 0;
                }

                button {
                    width: 100%;
                }
            }

            .course-title {
                color: $accent-color;
                font-weight: $font-weight-medium;
                font-size: 24px;
            }
        }

        .course-qr {
            img {
                width: 100px;
                height: auto;
                object-fit: contain;
            }
        }
    }

    .toggle-text {
        margin: 80px 10.5%;
        margin-bottom: 100px;
        // display: flex;
        // padding-bottom: 10px;
        // flex-direction: column;
        // gap: 20px;
        // align-items: flex-start;

        .toggle-title {
            outline: none;
            color: $light-opposite-color;
            border: none;
            background: transparent;
            padding: 0;
        }

        .toggle-title-active {
            outline: none;
            border: none;
            background: transparent;
            padding: 0;
            color: $accent-color;

            .link-line {
                width: 100%;
            }
        }

        .toggle-buttons {
            display: flex;
            flex-direction: row;
            gap: 30px;
            padding-bottom: 20px;
            justify-content: flex-start;
            border-bottom: 1px solid #d6d6d6;
        }

        .toggle-text-show {
            display: flex;
            gap: 20px;
            flex-direction: column;
            align-items: flex-start;

            .btn-default {
                font-size: 16px;
                font-weight: $font-weight-semi-bold;
                padding: 8px 26px;
            }
        }
    }

    .content-grid-skeleton {
        position: relative;
        width: 100%;
        min-height: 540px;
    }


    .course-text {
        animation-fill-mode: both;
        animation-duration: 1000ms;
        animation-delay: 0ms;
        animation-iteration-count: 1;
        opacity: 1;
        animation-name: react-reveal-567854384270007-1;
    }

    .about-sub-title {
        color: $accent-color;
        font-weight: $font-weight-normal;
        font-size: 18px;
    }

    .course-title {
        // text-transform: uppercase;
        font-size: 32px;
        font-weight: $font-weight-bold;
        margin-bottom: 20px;
    }


    // sidebar
    .course-sidebar {
        max-width: 300px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .course-qr {
            img {
                width: 160px;
                margin-left: -8px;
            }

        }

        .course-table {
            display: flex;
            grid-gap: 10px;
            gap: 10px;
            flex-direction: column;

            .content-title {
                color: $accent-color;
                font-weight: $font-weight-medium;
                font-size: 18px;
            }

            .course-content {
                display: flex;
                flex-direction: column;
            }
        }
    }

}


@media (max-width: breakpoint(sm-end)) {
    .Trainings-section {
        padding: 0 !important;
    }

    .toggle-text {
        margin: 60px 10.5% !important;
    }

    .course-sidebar {
        .course-qr {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }


    .content-grid {
        grid-template-columns: auto !important;
        gap: 60px !important;
    }

    .slider-aboutus {
        height: auto;

        .logo-slide {
            padding: 30px 0;
        }

        .block-posts__slider {
            flex-direction: column;
            gap: 30px;
        }
    }
}