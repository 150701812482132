@media (min-width: 1800px) and (max-width: 2400px) {
    .slider-aboutus {
        height: 200px;
    }
}

@media (min-width: 1400px) and (max-width: 1600px) {
    .AboutUs-home-section {
        .content-grid {
            grid-template-columns: 45% 50% !important;
            gap: 5% !important;
        }

        .logo-animation-section {
            width: 100% !important;
            height: auto !important;

            img {
                object-fit: contain;
            }
        }
    }

    .AboutUs-section {
        .content-grid {
            grid-template-columns: 50% 45% !important;
            gap: 5% !important;
        }

        .Page-header-section {
            margin: 0 10.5%;
            margin-bottom: 100px;
        }

        .logo-animation-section {
            width: 100% !important;
            height: auto !important;

            img {
                object-fit: contain;
            }
        }
    }

    .aboutBoxes-main {
        //flex-direction: column !important;
        flex-wrap: wrap !important;
        align-items: center !important;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .AboutUs-home-section {
        .content-grid {
            grid-template-columns: 45% 50% !important;
            gap: 5% !important;
        }

        .logo-animation-section {
            width: 100% !important;
            height: auto !important;

            img {
                object-fit: contain;
            }
        }
    }

    .AboutUs-section {
        .content-grid {
            grid-template-columns: 50% 45% !important;
            gap: 5% !important;
        }

        .Page-header-section {
            margin: 0 10.5%;
            margin-bottom: 100px;
        }

        .logo-animation-section {
            width: 100% !important;
            height: auto !important;

            img {
                object-fit: contain;
            }
        }
    }
}

// mobile section

@media (min-width: 992px) and (max-width: 1199px) {
    .AboutUs-home-section {
        .content-grid {
            grid-template-columns: 45% 50% !important;
            gap: 5% !important;
        }

        .logo-animation-section {
            width: 100% !important;
            height: auto !important;

            img {
                object-fit: contain;
            }
        }
    }

    .AboutUs-section {
        .content-grid {
            grid-template-columns: 50% 45% !important;
            gap: 5% !important;
        }

        .Page-header-section {
            margin: 0 10.5%;
            margin-bottom: 100px;
        }

        .logo-animation-section {
            width: 100% !important;
            height: auto !important;

            img {
                object-fit: contain;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .AboutUs-home-section {
        .content-grid {
            grid-template-columns: 45% 50% !important;
            gap: 5% !important;
        }

        .logo-animation-section {
            width: 100% !important;
            height: auto !important;

            img {
                object-fit: contain;
            }
        }
    }

    .AboutUs-section {
        .content-grid {
            grid-template-columns: auto !important;
            gap: 30px !important;
        }

        .Page-header-section {
            margin: 0 10.5%;
            margin-bottom: 100px;
        }

        .about-us-description {
            text-align: center;
        }

        .logo-animation-section {
            width: 100% !important;
            height: auto !important;

            img {
                object-fit: contain;
            }
        }
    }
}

@media (max-width: breakpoint(sm-end)) {
    .AboutUs-section {
        .Page-header-section {
            margin-bottom: 50px;
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {

    .AboutUs-home-section,
    .AboutUs-section {
        .content-grid {
            grid-template-columns: auto !important;
            gap: 30px !important;
            margin: 0 $page-mobile !important;
        }

        .about-header {
            .about-title {
                padding-bottom: 10px;
            }
        }

        .logo-animation-section {
            width: 100% !important;
            height: 100% !important;
        }

        .about-us-description {
            text-align: center;

            .toggle-text {
                align-items: center;
            }
        }
    }
}

@media (min-width: 280px) and (max-width: 575px) {

    .AboutUs-home-section,
    .AboutUs-section {
        .content-grid {
            grid-template-columns: auto !important;
            gap: 30px !important;
            margin: 0 $page-mobile !important;
        }

        // .about-header {
        //     .about-title {
        //         padding-bottom: 10px;
        //     }
        // }

        .logo-animation-section {
            width: 100% !important;
            height: 100% !important;
        }

        .about-us-description {
            text-align: center;

            .toggle-text {
                align-items: center;
            }
        }
    }

}