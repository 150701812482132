
.news-img-skeleton {
    .MuiSkeleton-rectangular {
        width: 555px;
        height: 100% !important;
    }
}

.news-header-skeleton {
    width: 100% !important;
}

.readMoreButton-skeleton {
    span {
        width: 200px;
    }
}