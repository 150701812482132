/*
// .search
*/
@import '../variables';

.site-header__search-top {
    display: none;
}

.site-header__search-topscroll {
    display: block;
}

.site-scroll__search {
    position: fixed;
    left: 0;
    right: 0;
    animation-duration: 500ms !important;
    top: 64px;
    padding: 10px 0;
    // bottom: 0;
    // height: 100vh;
    display: flex;
    z-index: 999;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.7);
}

.search--location--scrolled .search__input {
    outline: 0;
    border: none;
    border-color: #bfbfbf;
    outline: none;
    padding: 0 17px;
    // background: #fff url(../../img/Search-icone/search-input.png)  left 10px center no-repeat !important; search icone input
    padding-left: 50px;
    background-size: 24px 26px !important;
    width: 1px;
    flex-grow: 1;
    font-size: 15px;
    transition: color 0.15s;
    color: #000;
}

.search--location--scrolled .search__form {
    height: 40px;
    width: 100vh;
    display: flex;
    box-shadow: $offcanvas-search-shadow;
}

.search--location--scrolled .search__icone {
    color: #fff;
    font-weight: 600;
    fill: #bfbfbf;
}
.search--location--scrolled .search__button {
    border: none;
    padding: 8px 40px;
    background: #04408b;
    flex-grow: 0;
    color: white;
    font-weight: 500;
    width: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #bfbfbf;
    transition: fill 0.15s;

    &:focus {
        outline: none;
        // fill: $header-search-input-default-icon-hover-color;

        .search__icone {
            outline: none;
            // fill: $header-search-input-default-icon-hover-color;
        }
    }
}

.search__button:hover {
    outline: none;
    background: $hover-accent-color;
    transition: $button-transition;
}

.search-scroll-btn {
    border: none;
    padding: 8px 8px;
    background: $accent-color;
    color: $light-color;
    font-weight: $font-weight-semi-bold;
    flex-grow: 0;
    width: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: $header-search-input-default-icon-color;
    transition: fill .15s;

    &:hover,
    &:focus {
        outline: none;
        fill: $header-search-input-default-icon-hover-color;

        .search__icone {
            outline: none;
            fill: $header-search-input-default-icon-hover-color;
        } 
    }
}

.search-scroll-btn img {
    width: 25px;
    height: 25px;
}

// .search-scroll-close {
//     top: 30px;
//     right: 50px;
//     width: 55px;
//     padding: 0;
//     border: none;
//     padding-bottom: 5px;
//     /* margin: auto; */
//     background: $accent-color;
//     color: $light-color;
//     height: 55px;
//     display: flex;
//     font-size: 45px;
//     position: absolute;
//     align-content: center;
//     align-items: center;
//     justify-content: center;
// }

.search-scroll-close {
    /* top: 30px; */
    /* right: 50px; */
    left: 50px;
    width: 40px;
    padding: 0;
    border: none;
    padding-bottom: 5px;
    /* margin: auto; */
    background: #04408b;
    color: #fff;
    height: 40px;
    display: flex;
    font-size: 45px;
    position: relative;
    align-content: center;
    align-items: center;
    justify-content: center;
}