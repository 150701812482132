// mobile section
@media (min-width: 1401px) and (max-width: 1600px) {
    .footer-nav {
        gap: 80px;
    }

    .links-section-footer {
        gap: 180px;
    }
}

@media (min-width: 1201px) and (max-width: 1400px) {
    .footer-nav {
        gap: 60px;
    }

    .links-section-footer {
        gap: 150px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .footer-nav {
        gap: 60px;
    }

    .links-section-footer {
        gap: 75px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .footer-nav {
        align-items: baseline;
        justify-content: space-between;
        gap: 130px;
    }

    .logo-footer-grid {
        align-items: center;

        .site-footer__logo {
            img {
                width: 100%;
                object-fit: contain;
                max-width: 380px;
            }
        }
    }

    .copyright-container {
        margin: 0;
    }

    .site-footer__widgets {
        margin: 0px 9%;
    }

    .links-section-footer {
        flex-direction: column;
    }

    .links-section-footer {
        gap: 60px !important;
    }
}


@media (max-width: breakpoint(sm-end)) {
    .site-footer__widgets {
        margin: 0px !important;
    }

    .logo-footer-grid {
        align-items: center;

        .site-footer__logo {
            img {
                object-fit: contain;
                width: 100%;
                max-width: 380px;
            }
        }
    }

    .footer-nav {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .links-ft {
        text-align: center !important;
    }

    .links-section-footer {
        flex-direction: column !important;
        gap: 40px !important;
    }

    .site-footer__widgets {
        padding-bottom: 0px !important;
    }

    .footer {
        padding: 35px 35px 0px !important;
    }

    .footer-contact-text {
        justify-content: center !important;
    }

    .totop__body {
        justify-content: center !important;
    }

    .totop__start {
        display: none !important;
    }

    .totop__container {
        display: none !important;
    }

    .totop__end {
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .totop__button {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

@media (max-width: breakpoint(xs-end)) {
    .site-footer__widgets {
        margin: 0px !important;
    }

    .links-ft {
        text-align: center !important;
    }

    .links-section-footer {
        flex-direction: column !important;
        gap: 40px !important;
    }

    .site-footer__widgets {
        padding-bottom: 0px !important;
    }

    .footer {
        padding: 35px 35px 0px !important;
    }

    .footer-contact-text {
        justify-content: center !important;
    }

    .totop__body {
        justify-content: center !important;
    }

    .totop__start {
        display: none !important;
    }

    .totop__container {
        display: none !important;
    }

    .totop__end {
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .totop__button {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

}