@media (max-width: breakpoint(lg-end)) {}

@media (max-width: breakpoint(md-end)) {
    .Slider-Section {
        height: 450px !important;
    }

    .Slider-Section .Slider-home,
    .Slider-Section .Slider-img,
    .Slider-Section .lazy-load-image-background {

        height: 450px !important;
    }

    .Slider-Section .Slider-home .Slider-Caption .caption-title {
        font-size: 55px !important;
        line-height: 30px !important;
    }

    .Slider-Section .Slider-home .Slider-Caption .caption-text {
        font-size: 23px !important;
    }
}

@media (max-width: breakpoint(sm-end)) {
    .Slider-Section {
        height: 400px !important;

        .btn-default button {
            padding: 8px 16px;
            font-size: 14px;
        }
    }

    .Slider-Section .Slider-home .Slider-Caption {
        max-width: 90vw !important;
    }

    .Trainings-section .Slider-Section .Slider-Caption .caption-text,
    .Slider-Section .Slider-home .Slider-Caption .caption-text {
        line-height: 1.5rem;
    }

    .Slider-Section .Slider-home,
    .Slider-Section .Slider-img,
    .Slider-Section .lazy-load-image-background {

        height: 400px !important;
    }

    .Slider-Section .Slider-home .Slider-Caption {

        grid-gap: 25px !important;
        gap: 25px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .Slider-Section .Slider-home .Slider-Caption .caption-title {
        font-size: 36px !important;
        line-height: 30px !important;
    }


    .Slider-Section .Slider-home .Slider-Caption .caption-text {
        font-size: 26px !important;
    }
}

@media (max-width: breakpoint(xs-end)) {
    .Slider-Section {
        height: 280px !important;
    }

    .Slider-Section .Slider-home,
    .Slider-Section .Slider-img,
    .Slider-Section .lazy-load-image-background {

        height: 280px !important;
    }

    .Slider-Section .Slider-home .Slider-Caption {

        grid-gap: 10px !important;
        gap: 10px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .Slider-Section .Slider-home .Slider-Caption .caption-title {
        font-size: 26px !important;
        line-height: 30px !important;
    }


    .Slider-Section .Slider-home .Slider-Caption .caption-text {
        font-size: 16px !important;
    }
}