// mobile section

@media (min-width: 1601px) and (max-width: 1800px) {
    .Projects-section {
        .Page-header-section {
            margin: 0 10% !important;
            margin-bottom: 30px !important;
        }
    }
}

@media (min-width: 1400px) and (max-width: 1600px) {
    .projects-slideshow__project-content {
        margin: 100px $page-mobile;

        .Page-header-section {
            margin: 0 !important;
            margin-bottom: 50px !important;
        }
    }

    .Projects-section {
        .Page-header-section {
            margin: 0 9% !important;
            margin-bottom: 30px !important;
        }
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .Projects-home-section {
        margin: 75px $page-mobile;

        .Page-header-section {
            margin: 0 !important;
            margin-bottom: 50px !important;
        }
    }

    .Projects-section {
        .Page-header-section {
            margin: 0 6% !important;
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .projects-slideshow__project-content {
        margin: 100px $page-mobile;

        .Page-header-section {
            margin: 0 !important;
            margin-bottom: 50px !important;
        }
    }

    .projects-boxes {
        grid-template-columns: 29.2% 29.2% 29.2%;
        justify-content: space-between !important;
    }

    .Projects-section {
        .Page-header-section {
            margin: 0 !important;
        }

        .project-boxes-content{
            min-height: 390px !important;
        }
    }
}

@media (max-width: breakpoint(md-end)) {
    .projects-slideshow__project-content {
        margin: 75px $page-mobile;

        .Page-header-section {
            margin: 0 !important;
            margin-bottom: 50px !important;

            .Page-title {
                font-size: 46px;
            }

            .Page-sub-title {
                line-height: 45px;
                font-size: 28px;
            }
        }
    }

    .projects-boxes {
        grid-template-columns: 46% 46%;
        justify-content: space-between !important;
    }

    .Projects-section {
        margin: 0 !important;
        padding: 100px 0%;

        .Page-header-section,
        .toggle-section {
            margin: 0 $page-dekstop !important;
            margin-bottom: 30px !important;
        }
    }

}

@media (max-width: breakpoint(sm-end)) {
    .projects-slideshow__project-content {
        margin: 50px $page-mobile;

        .Page-header-section {
            margin-bottom: 50px !important;

            .Page-title {
                font-size: 42px;
            }

            .Page-sub-title {
                line-height: 40px;
                font-size: 26px;
            }
        }
    }

    .projects-boxes {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 30px !important;
    }

    .Projects-section {

        .Page-header-section,
        .toggle-section {
            margin: 0 $page-mobile !important;
        }

        .animation-img-bg {
            margin-left: 30px;
        }
    }
}

@media (max-width: breakpoint(xs-end)) {}