.btn-default {
   button {
      background-color: $btn-primary-bg-color;
      color: $light-color;
      font-size: 20px;
      font-weight: $font-weight-semi-bold;
      padding: 16px 28px;
      border-radius: 6px;
      text-transform: uppercase;
      line-height: 24px;
      transition: 0.2s ease-in-out;

      &:hover {
         color: $light-color;
         background-color: $btn-primary-bg-color-hover;
         transition: 0.2s ease-in-out;
      }
   }
}

.btn-default-sm {
   button {
      background-color: $btn-primary-bg-color;
      color: $light-color;
      font-size: 16px;
      font-weight: $font-weight-semi-bold;
      padding: 10px 18px;
      border-radius: 6px;
      line-height: 20px;
      text-transform: uppercase;
      border: 1px solid $accent-color;
      // transition: 0.4s ease-in-out;

      &:hover {
         background-color: transparent;
         border: 1px solid $accent-color;
         color: $accent-color;
         // transition: 0.4s ease-in-out;

      }
   }
}

.btn-fam {
   button {
      font-family: "Montserrat";
      font-style: normal;
   }
}

.btn-pages {
   font-size: 20px;
   font-weight: $font-weight-medium;
   text-transform: capitalize;
   display: flex;
   flex-direction: column;

   .link-line {
      border-bottom: 2px solid $btn-primary-bg-color;
      width: 52px;
      transition: 0.5s ease-in-out;
   }

   &:hover {
      color: $light-opposite-color;

      .link-line {
         transition: 0.5s ease-in-out;
         width: 100%;
      }
   }
}

.btn-transparent {
   button {
      background-color: transparent;
      color: $light-color;
      font-size: 16px;
      font-weight: $font-weight-semi-bold;
      padding: 12px 32px;
      border: 1px solid white;
      border-radius: 6px;
      text-transform: uppercase;

      &:hover {
         background-color: $accent-color;
         border: 1px solid $accent-color;
         border-radius: 6px;
         color: $light-color;
         transition: 0.4s ease-in-out;

      }
   }
}


.btn-transparent-project {
   button {
      background-color: transparent;
      color: $accent-color;
      font-size: 16px;
      font-weight: $font-weight-semi-bold;
      padding: 10px 26px;
      border: 1px solid $accent-color;
      border-radius: 6px;
      line-height: 20px;
      text-transform: uppercase;
      // transition: 0.4s ease-in-out;

      &:hover {
         background-color: $accent-color;
         border: 1px solid $accent-color;
         color: $light-color;
         border-radius: 6px;
         // transition: 0.4s ease-in-out;

      }
   }
}