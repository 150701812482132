.project-top-aboutus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-bottom: 30px;
    margin: 0 10.5%;
    margin-top: 100px;
}

.AboutUs-section {
    .content-grid {
        gap: 140px;
        display: grid;
        grid-template-columns: auto auto;
        margin: 0 10.5%;
    }

    .content-grid-skeleton {
        position: relative;
        width: 100%;
        min-height: 540px;
    }


    .about-text {
        animation-fill-mode: both;
        animation-duration: 1000ms;
        animation-delay: 0ms;
        animation-iteration-count: 1;
        opacity: 1;
        animation-name: react-reveal-567854384270007-1;
    }

    .about-sub-title {
        color: $accent-color;
        font-weight: $font-weight-normal;
        font-size: 18px;
    }

    .about-title {
        // text-transform: uppercase;
        font-size: 32px;
        font-weight: $font-weight-bold;
        margin-bottom: 20px;
    }

    .logo-animation-section {
        position: relative;
        width: 658px;
        height: 587px;

        .content-img {
            z-index: 3;
            position: relative;
        }

        .animation-img {
            position: absolute;
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}