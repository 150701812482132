.SubPage-Section {
    margin-top: 30px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: $block-margin-bottom;

    .collapse-content {
        display: none;

        .page-slider-section .slick-dots {
            display: none;
        }
    }

    .title-collapse {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card-title {
        margin: 0;
    }
    
    .title-collapse {
        &:focus-visible {
            outline: none !important;
        }
    }

    .collapse-box {
        background-color: $contact-background;
        // height: 100%;
        height: auto;
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        width: 100%;
        // border: 1px solid $border-blue-box;
        border: 1px solid #c7c7c721;
        ;
        // box-shadow: $block-box-shadow;
    }

    .menu__item--open {

        .page-slider-section .slick-dots {
            display: block;
        }

        .collapse-box {
            .collapse-content {
                margin-top: 30px;
                display: block;
                // transition-delay: 0s, 0s, 0s;
                transition: 0.2s ease-out;
                overflow: hidden;
            }
        }

        .widget-categories__expander::after {
            transform: rotateZ(90deg);
        }
    }

    .subpage-docs {
        margin-top: $block-margin-top;
    }

    .expander {
        .widget-categories__expander {
            margin-right: 0 !important;
            margin-top: 0 !important;
        }
    }
}

.widget-categories__expander {
    $local-size: 28px;
    $local-icon-size: 8px;

    margin-top: 1px;
    width: $local-size;
    height: $local-size;
    position: relative;
    border: none;
    border-radius: 1.5px;
    cursor: pointer;
    padding: 0;
    background: $widget-categories-expander-default-bg;
    color: #000000e0;
    transition: all .2s;

    @include direction {
        #{$margin-inline-end}: (($local-size - $local-icon-size) * .5) * -1;
    }

    &:focus,
    &:hover {
        background: $widget-categories-expander-hover-bg;
        color: $link-hover-color;
    }

    &:focus {
        outline: none;
    }

    &::before,
    &::after {
        display: block;
        position: absolute;
        content: '';
        background: currentColor;
    }

    &::before {
        width: $local-icon-size;
        height: 2px;
        top: calc(50% - 1px);

        @include direction {
            #{$inset-inline-start}: calc(50% - #{$local-icon-size * .5});
        }
    }

    &::after {
        width: 2px;
        height: $local-icon-size;
        top: calc(50% - #{$local-icon-size * .5});
        transition: transform $local-expand-transition-duration;

        @include direction {
            #{$inset-inline-start}: calc(50% - 1px);
        }
    }
}

@media (min-width: 280px) and (max-width: 767px) {

    .collapse-content {
        .page-slider-section .slick-dots {
            visibility: hidden !important;
        }
    }

    .menu__item--open {

        .page-slider-section .slick-dots {
            visibility: visible !important;
        }

    }
}