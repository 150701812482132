.footer-top {
    height: 235px;
    display: flex;
    position: relative;

    .overlay {
        position: absolute;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
        width: 100%;
        height: 100%;
        top: 0;
        height: 235px;
    }

    .footer-parallax-title {
        margin: 0;
    }

    .photo-menu-home {
        // background: url(../../img/slider-footer/footer-slider1.png);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        width: 100%;
        position: relative;
    }

    @supports (-webkit-touch-callout: none) {
        .photo-menu-home {
            background-attachment: scroll;
        }
    }

    .PageSlider {
        height: 235px;
    }
    // .photo-menu-home{
    //     .block-slideshow-contact__slide-content{
    //         background: #00000080;
    //     }
    // }
    .block-slideshow__slide-content {
        position: relative;
        width: 100%;
        text-align: center;
        max-width: 100%;
        padding-top: 0px;
        padding-left: 0px;
        z-index: 10;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-direction: row;
        height: 235px;
        justify-content: space-around;
    }

    .block-slideshow__slide-content h1 {
        color: white;
    }
}