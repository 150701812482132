// mobile section

@media (max-width: breakpoint(lg-end)) {
    //code
}

@media (max-width: breakpoint(md-end)) {}

@media (max-width: breakpoint(sm-end)) {
    .footer-top {
        height: 210px !important;

        .photo-menu-home {
            display: flex;
            align-items: center;
        }


        .overlay {
            height: 210px !important;
        }

        .block-slideshow__slide-content {
            gap: 30px;
            padding: 0px 20px;
            flex-wrap: wrap !important;
            height: 180px !important;
        }
    }
}

@media (max-width: breakpoint(xs-end)) {
    .footer-top {
        height: 255px !important;

        .photo-menu-home {
            display: flex;
            align-items: center;
        }


        .overlay {
            height: 255px !important;
        }

        .block-slideshow__slide-content {
            padding: 0px 20px;
            flex-wrap: wrap !important;
            justify-content: center !important;
            height: 180px !important;
        }
    }
}