.basic-page {
    min-height: 100vh;
}

.page-section {
    margin: 0px 13.5%;
    padding-bottom: 100px;

    .card-image {
        width: 100%;
        height: 100%;

        img {
            width: 100% !important;
            // max-height: 560px;
            // object-fit: contain !important;
            // box-shadow: none;
            height: 100% !important;
        }
    }

    .page-content-half {
        margin-bottom: 100px;
        min-height: 500px;
    }

    .card-image-half {
        img {
            width: 60% !important;
            float: left;
            margin-right: 30px;
            object-fit: cover;
            box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
        }
    }

    .page-title {
        margin: 35px 30px;
    }

    .page-title-half {
        margin: 35px 0px;
    }

    .page-breadcrumbs {
        margin: 30px 30px;

        a {
            color: $accent-color;
            font-size: 18px;
            font-family: 'Montserrat';
        }

        .mantine-Breadcrumbs-separator {
            color: $accent-color;
            font-size: 18px;
        }

        .mantine-qa5af0 {
            margin-left: 0.325rem;
            margin-right: 0.325rem;
            font-family: 'Montserrat';
        }
    }

    .page-breadcrumbs-half {
        margin: 30px 0px;

        a {
            color: $accent-color;
            font-size: 18px;
            font-family: 'Montserrat';
        }

        .mantine-Breadcrumbs-separator {
            color: $accent-color;
            font-size: 18px;
        }

        .mantine-qa5af0 {
            margin-left: 0.325rem;
            margin-right: 0.325rem;
            font-family: 'Montserrat';
        }
    }

    .page-text {
        margin-top: 40px;
    }
}


@media (min-width: 3201px) and (max-width: 5200px) {
    .page-box {
        display: grid;
        grid-template-columns: auto auto;
        gap: 60px;
    }

    .page-section .page-text {
        margin: 0;
    }

    .page-content-half {
        min-height: 900px !important;

        .card-image-half img {
            width: 40% !important;
        }
    }
}

@media (min-width: 2000px) and (max-width: 3200px) {
    .page-box {
        display: grid;
        grid-template-columns: auto auto;
        gap: 60px;
    }

    .page-section .page-text {
        margin: 0;
    }

    .page-content-half {
        min-height: 600px !important;

        .card-image-half img {
            width: 40% !important;
        }
    }
}

@media (max-width: breakpoint(lg-end)) {
    .page-content-half {
        .news-text {
            font-size: 18px !important;
        }
    }
}

@media (max-width: breakpoint(md-end)) {
    .page-section {
        margin: 0 $page-mobile;

        .page-title {
            margin: 35px 0;
        }

        .card-image-half {
            img {
                float: none !important;
                width: 100% !important;
            }
        }

        .page-breadcrumbs {
            margin: 35px 0;
        }
    }
}

@media (max-width: breakpoint(sm-end)) {
    .page-section {
        margin: 0 $page-mobile;
        padding-bottom: 50px;

        .page-title,
        .page-title-half {
            margin: 25px 0;
        }

        .page-breadcrumbs,
        .page-breadcrumbs-half {
            margin: 30px 0;
            margin-bottom: 20px;
        }
    }

    .page-content-half {
        .news-text {
            font-size: 16px !important;
        }
    }

}

@media (max-width: breakpoint(xs-end)) {}