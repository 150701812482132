@media (min-width: 1800px) and (max-width: 2400px) {
    
}
@media (max-width: breakpoint(lg-end)) {

}
@media (max-width: breakpoint(md-end)) {
   
}
@media (max-width: breakpoint(sm-end)) {
    .aboutus-top{
        height: 500px;
    }
    .aboutus-top .aboutus-mission{
        flex-direction: column;
        gap:0px;
        padding: 20px 0;
    }
    .aboutus-top .about-feature{
        text-align: center;
        align-items: center;
    }
    .aboutus-top .overlay{
        height: 500px;
    }
}
@media (max-width: breakpoint(xs-end)) {
    .aboutus-top{
        height: 500px;
    }
    .aboutus-top .aboutus-mission{
        flex-direction: column;
        gap:0px;
        padding: 20px 0;
    }
    .aboutus-top .about-feature{
        text-align: center;
        align-items: center;
    }
    .aboutus-top .overlay{
        height: 500px;
    }
}