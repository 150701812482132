@media (min-width: 1601px) and (max-width: 1800px)  {
    .projects-box-link .info h1 {
        padding: 20px;
        font-size: 24px;
    }
}

@media (min-width: 1401px) and (max-width: 1600px)  {
    .projects-box-link .info h1 {
        padding: 20px;
        font-size: 22px;
    }
}

@media (min-width: 1201px) and (max-width: 1400px)  {
    .projects-box-link .info h1 {
        padding: 15px;
        font-size: 18px;
    }
}

@media (min-width: 992px) and (max-width: 1200px)  {
    .projects-box-link .info h1 {
        padding: 10px;
        font-size: 16px;
    }
}

@media (min-width: 768px) and (max-width: 991px)  {
    .projects-box-link .info h1 {
        padding: 10px;
        font-size: 20px;
    }
}

@media (min-width: 531px) and (max-width: 680px)  {
    .projects-box-link .info h1 {
        padding: 20px;
        font-size: 26px;
    }
}

@media (min-width: 431px) and (max-width: 530px)  {
    .projects-box-link .info h1 {
        padding: 20px;
        font-size: 24px;
    }
}