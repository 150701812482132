.block-posts__slider{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.slider-aboutus{
    height: 180px;
    background-color: #FAFAFA;
}
.logo-box img {
    width: 180px;
}
.logo-slide{
    margin: 0 10.5%;
    height: 100%;
}