.aboutusContainer{
    margin: 0 10.5%;
    padding-top: 100px;
    padding-bottom: 100px;

    .Page-header-section {
        margin: 0px 0%;
    }
    
}

.aboutBoxes-main{
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 70px 0px;
}

.aboutbox{
    background: #FDFDFD;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    width: 299px;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    grid-gap: 10px;
    gap: 10px;

    img{
    object-fit: contain;
    height: 60px;
    width: 60px;
    }
}

.aboutusbox-title{
    font-size: 24px bold;
    margin: 0px;

}
.aboutusbox-text{
    font-size: 16px;
    margin: 0px;
}