.Page-header-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    gap: 30px;
    margin: 0px 22%;
}

.Page-title {
    font-size: 48px;
    font-weight: $font-weight-normal;
    margin: 0;
}

.Page-sub-title {
    text-align: center;
    line-height: 50px;
    font-size: 36px;
}

.Page-body {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*
// .block-banner
*/
@import '../variables';
@import '../mixins/preloader';


.block-loader {
    position: relative;
    min-height: 320px;
}

.block-loader__spinner {
    @include preloader(80px);
}


.post-loader {
    position: inherit !important;
    min-height: 80vh !important;
}

.post-spinner {
    top: calc(50% - 20vh) !important;
    // border-top-color: $accent-color;
}

.posts-view__pagination {
    .mantine-1n8gmw6[data-active] {
        background-color: $accent-color;
    }
}

@media (hover: hover) {
    .posts-view__pagination {
        .mantine-1n8gmw6[data-active]:not([data-disabled]):hover {
            background-color: #2170b3;
        }
    }

}



// mobile section

@media (max-width: breakpoint(lg-end)) {
    //code
}

@media (max-width: breakpoint(md-end)) {}

@media (max-width: breakpoint(sm-end)) {
    .Page-header-section {
        margin: 0 $page-mobile;
    }

    .Page-body {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }
}

@media (max-width: breakpoint(xs-end)) {}


@media (min-width: 360px) and (max-width: 430px) {

    .page-content {
        .page-title {
            font-size: 32px;
        }
    }

    .page-content-half {
        .page-title-half {
            font-size: 32px;
        }
    }


    .Page-header-section {
        .Page-title {
            font-size: 38px;
        }

        .Page-sub-title {
            font-size: 30px;
        }
    }

    .aboutus-top .about-feature h1 {
        font-size: 32px !important;
    }


    .Services-home-section {
        .services-description {
            gap: 5px;

            .services-title {
                font-size: 42px !important;
            }

            .services-text {
                font-size: 16px !important;
            }
        }
    }

    .AboutUs-home-section {
        .about-title {
            font-size: 32px !important;
        }
    }

    .Projects-home-section {
        margin: 40px 9% !important;

        .Page-title {
            font-size: 42px !important;
        }

        .Page-header-section {
            margin-bottom: 30px !important;
        }

        .Page-sub-title {
            font-size: 24px !important;
        }
    }

    .footer-top .block-slideshow__slide-content {
        padding: 0 10% !important;

        .footer-parallax-title {
            font-size: 32px !important;
        }
    }

    .btn-transparent {
        button {
            padding: 8px 20px;
        }
    }

    .projects-box-link .info h1 {
        padding: 15px;
        font-size: 22px;
    }
}

@media (min-width: 280px) and (max-width: 359px) {
    .Services-home-section {
        padding-top: 30px !important;
        padding-bottom: 30px !important;

        .services-description {
            gap: 5px;

            .services-title {
                font-size: 32px !important;
            }

            .services-text {
                font-size: 14px !important;
            }
        }

        .services-boxes {
            .content-title {
                font-size: 20px !important;
            }

            .contnet-text {
                font-size: 14px;
            }
        }
    }

    .Page-header-section {
        .Page-title {
            font-size: 32px;
        }

        .Page-sub-title {
            font-size: 24px;
        }
    }

    .aboutBoxes-main {
        .aboutusbox-title {
            font-size: 20px;
        }

        .aboutusbox-text {
            font-size: 14px;
        }
    }

    .aboutus-top .about-feature {
        h1 {
            font-size: 28px;
        }

        p {
            font-size: 14px;
        }

    }

    .AboutUs-section {
        .about-title {
            font-size: 38px;
        }

        .about-text {
            font-size: 14px;
        }
    }

    .AboutUs-home-section {
        .about-sub-title {
            font-size: 16px !important;
        }

        .about-title {
            font-size: 32px !important;
        }

        .about-text,
        .toggle-text-show {
            font-size: 14px;
        }
    }

    .Services-section {
        .card-title {
            font-size: 20px;
        }

        .card-text {
            font-size: 14px;
        }
    }

    .contact-section .contact-display-contnet .contact-form .contact-header {
        h1, 
        span {
            font-size: 34px !important;
        }
    }

    .news-Main {
        .news-title {
            font-size: 20px;
        }

        .news-date {
            font-size: 16px;
            margin-bottom: 10px;
        }
    }

    .Projects-home-section {
        margin: 40px 9% !important;

        .Page-title {
            font-size: 32px !important;
        }

        .Page-header-section {
            margin-bottom: 30px !important;
        }

        .Page-sub-title {
            font-size: 22px !important;
            line-height: 36px !important;
        }
    }

    .btn-pages {
        font-size: 16px !important;
    }

    .btn-default-sm button {
        font-size: 14px;
        padding: 8px 14px;
    }

    .btn-transparent-project button {
        font-size: 14px;
        padding: 8px 14px;
    }

    .btn-transparent {
        button {
            font-size: 14px;
            padding: 8px 14px;
        }
    }

    .projects-box-link .info h1 {
        padding: 10px;
        font-size: 16px;
    }
}