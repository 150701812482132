@media (min-width: 3400px) and (max-width: 5400px) {
    .Services-section {
        .services-boxes {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }
    }
}

@media (min-width: 2400px) and (max-width: 3399px) {
    .Services-section {
        .services-boxes {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}

@media (min-width: 1400px) and (max-width: 1499px) {
    .Services-content {
        .card-image img {
            width: 500px;
            height: 320px;
        }
    }

    .Services-content-grid {
        .card-image img {
            width: 500px;
            height: 320px;
        }
    }

    .Services-content-grid .card-left {
        max-width: 500px;
    }

    .Services-content-grid .card-right {
        max-width: 500px;
    }
}

@media (min-width: 1301px) and (max-width: 1399px) {

    .Services-section {
        .Page-header-section {
            margin: 0 12.5%;
            margin-bottom: 100px;
        }
    }

    .Services-content {
        gap: 80px !important;

        .card-image img {
            width: 480px;
            height: 300px;
        }

        .card-left,
        .card-right {
            align-items: center;
        }
    }

    .Services-content-grid {
        .card-image img {
            width: 480px;
            height: 300px;
        }
    }

    .Services-content-grid .card-left {
        max-width: 480px;
    }

    .Services-content-grid .card-right {
        max-width: 480px;
    }
}

@media (min-width: 1201px) and (max-width: 1300px) {

    .Services-section {
        .Page-header-section {
            margin: 0 10.5%;
            margin-bottom: 100px;
        }
    }

    .Services-content {
        gap: 80px !important;

        .card-image img {
            width: 430px;
            height: 280px;
        }

        .card-left,
        .card-right {
            align-items: center;
        }
    }

    .Services-content-grid {
        .card-image img {
            width: 430px;
            height: 280px;
        }
    }

    .Services-content-grid .card-left {
        max-width: 430px;
    }

    .Services-content-grid .card-right {
        max-width: 430px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .Services-section {
        .Page-header-section {
            margin: 0 10.5%;
            margin-bottom: 100px;
        }

        .services-boxes {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    .Services-content {
        gap: 80px !important;

        .card-image img {
            width: 360px;
            height: 230px;
        }

        .card-left,
        .card-right {
            align-items: center;

            .card-textContent {
                gap: 5px !important;

                .btn-pages {
                    font-size: 18px !important;
                }
            }

            .card-title {
                margin: 0;
                font-size: 22px !important;
            }

            .card-text {
                font-size: 14px !important;
                margin: 0;
            }

        }
    }

    .Services-content-grid {
        .card-image img {
            width: 360px;
            height: 230px;
        }

        .card-left,
        .card-right {
            .card-textContent {
                gap: 5px !important;

                .btn-pages {
                    font-size: 18px !important;
                }
            }

            .card-title {
                margin: 0;
                font-size: 22px !important;
            }

            .card-text {
                font-size: 14px !important;
                margin: 0;
            }

        }

    }

    .Services-content-grid .card-left {
        max-width: 360px;
    }

    .Services-content-grid .card-right {
        max-width: 360px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .Services-section {
        .Page-header-section {
            margin: 0 10.5%;
            margin-bottom: 100px;
        }

        .services-boxes {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .card-left,
        .card-right {
            .card-textContent {
                .btn-pages {
                    font-size: 20px !important;
                }
            }

            .card-title {
                margin: 0;
                font-size: 24px !important;
            }

            .card-text {
                font-size: 18px !important;
                margin: 0;
            }

        }

    }

    .Services-content-grid .card-left {
        max-width: none;
    }

    .Services-content-grid .card-right {
        max-width: none;
    }

    .Services-content {
        .card-left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .card-right {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
        }

        .card-image img {
            width: 100%;
            height: auto;
            max-height: 430px;
        }

        .card-left .card-textContent {
            margin-left: 0px;
            gap: 0px;
        }

        .card-right .card-textContent {
            margin-right: 0px;
            gap: 0px;
        }

        .card-left .card-title {
            margin-top: 20px;
        }

        .card-right .card-title {
            margin-top: 20px;
        }
    }

    .Services-content-grid {
        flex-direction: column;

        .card-left {
            display: flex;
            flex-direction: column;
        }

        .card-right {
            display: flex;
            flex-direction: column-reverse;
        }

        .card-image img {
            width: 100%;
            height: auto;
        }

        .card-left .card-textContent {
            margin-left: 0px;
            gap: 0px;
            margin-top: 20px !important;
        }

        .card-right .card-textContent {
            margin-right: 0px;
            gap: 0px;
            margin-top: 20px !important;
        }
    }

    .toggle-section .toggle-buttons {
        flex-wrap: wrap;
        gap: 30px;
        justify-content: center;
    }

    .Page-header-section {
        text-align: center;
    }

    // .Page-body{
    //     padding-top: 20px;
    //     padding-bottom: 20px;
    // }    
    .toggle-section {
        gap: 40px;
    }

}

@media (min-width: 576px) and (max-width: 767px) {

    .Services-section {
        .Page-header-section {
            margin: 0 $page-mobile;
            margin-bottom: 50px;
        }
    }

    .Services-content {
        margin: 0 $page-mobile !important;

        .card-left {
            display: flex;
            flex-direction: column;
        }

        .card-right {
            display: flex;
            flex-direction: column-reverse;
        }

        .card-image img {
            width: 100%;
            height: auto;
            max-height: 380px;
        }

        .card-left .card-textContent {
            margin-left: 0px;
            gap: 0px;
        }

        .card-right .card-textContent {
            margin-right: 0px;
            gap: 0px;
        }

        .card-left .card-title {
            margin-top: 20px;
        }

        .card-right .card-title {
            margin-top: 20px;
        }
    }

    .Services-content-grid {
        margin: 0 $page-mobile !important;
        flex-direction: column;

        .card-left {
            display: flex;
            flex-direction: column;
        }

        .card-right {
            display: flex;
            flex-direction: column-reverse;
        }

        .card-image img {
            width: 100%;
            height: auto;
        }

        .card-left .card-textContent {
            margin-left: 0px;
            gap: 0px;
            margin-top: 20px !important;
        }

        .card-right .card-textContent {
            margin-right: 0px;
            gap: 0px;
            margin-top: 20px !important;
        }
    }

    .toggle-section .toggle-buttons {
        flex-wrap: wrap;
        gap: 30px;
        justify-content: center;
    }

    .Page-header-section {
        text-align: center;
    }

    // .Page-body{
    //     padding-top: 20px;
    //     padding-bottom: 20px;
    // }    
    .toggle-section {
        gap: 40px;
    }
}

@media (max-width: breakpoint(sm-end)) {
    .Services-section {
        .services-boxes {
            display: grid;
            gap: 50px;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            margin: 0px $page-mobile;
            margin-top: 100px;
            margin-bottom: 50px;
            justify-items: center;

            .content-box {
                text-align: center;
                background: #FDFDFD;
                box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
                padding: 30px;
                width: 100%;
                max-width: 460px;
                align-items: center !important;

                .content-header {
                    flex-direction: column;
                    align-items: center;
                }
            }

            .content-logo {
                width: 60px;
                height: 60px;
                object-fit: contain;
            }
        }
    }
}




@media (min-width: 280px) and (max-width: 575px) {

    .Services-body .Page-header-section {
        margin: 0px 9%;
        margin-bottom: 50px;
    }

    .toggle-section .toggle-buttons {
        flex-wrap: wrap;
        gap: 30px;
        justify-content: center;
    }

    .Page-header-section {
        text-align: center;
    }

    .Services-content {
        .card-left {
            display: flex;
            flex-direction: column;
        }

        .card-right {
            display: flex;
            flex-direction: column-reverse;
        }

        .card-image img {
            width: 100%;
            height: auto;
        }

        .card-left .card-textContent {
            margin-left: 0px;
            gap: 0px;
        }

        .card-right .card-textContent {
            margin-right: 0px;
            gap: 0px;
        }

        .card-left .card-title {
            margin-top: 20px;
        }

        .card-right .card-title {
            margin-top: 20px;
        }
    }

    .Services-content-grid {
        flex-direction: column;

        .card-left {
            display: flex;
            flex-direction: column;
        }

        .card-right {
            display: flex;
            flex-direction: column-reverse;
        }

        .card-image img {
            width: 100%;
            height: auto;
        }

        .card-left .card-textContent {
            margin-left: 0px;
            gap: 0px;
            margin-top: 20px !important;
        }

        .card-right .card-textContent {
            margin-right: 0px;
            gap: 0px;
            margin-top: 20px !important;
        }
    }

    // .Page-body{
    //     padding-top: 20px;
    //     padding-bottom: 20px;
    // }
    .toggle-section {
        gap: 40px;
    }
}