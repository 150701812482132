.footer {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    flex-wrap: wrap;
    width: 100%;
    padding: $body-container-padding;
}

.footer-row {
    justify-content: space-between;
    display: flex;
    margin: auto;
    max-width: 100%;
    margin-top: 35px;
    flex-direction: row;
    flex-wrap: nowrap;
}

.footer-nav {
    display: flex;
    flex-direction: row;
    gap: 150px;
}

.site-footer {
    // margin-top: 30px;
    background: $footer-bg;
    color: $light-color;
    border-top: $footer-border-top;
}

.site-footer__widgets2 {
    padding: 50px 70px 50px 70px;

}

.site-footer__widgets3 {
    padding: 50px 70px 50px 70px;
    display: flex;
    justify-content: center;

}

.site-footer__widgets {
    margin: $body-container-margin;
    width: 100%;
}

.footer-links-box {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
}

.footer-links__item {
    line-height: 20px;
}

.site-footer__bottom {
    background-color: $light-color;
    color: $footer-copyright-color;
    padding: 0 10px;
    height: 95px;
    display: flex;
    align-items: center;
}

.copyright-container {
    width: 100%;
    border-top: 1px solid $footer-copyright-border;
    margin: 0 10vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.links-ft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer__bottom {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    align-content: center;
    justify-content: center;
}

.site-footer__copyright {
    font-size: 15px;
    color: $footer-copyright-color;

    a:hover {
        text-decoration: underline;
    }
}

.site-footer__payments {
    img {
        max-width: 100%;
    }
}

.links-section-footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 100px;
    justify-content: space-between;
}


.site-footer__widget {
    display: flex;
    gap: 20px;
    flex-direction: column;

    h5 {
        margin: 0;
    }

    &+& {
        margin-top: 24px;
    }
}

.site-footer__widget2 {
    &+& {
        margin-top: 24px;
    }
}

.logo-footer-grid {
    gap: 30px;
    display: flex;
    flex-direction: column;
}

.site-footer__logo {
    // margin-bottom: 12px;

    img {
        max-width: 100%;
        // width: 100%;
    }
}

.footer-img-box {
    // margin-right: 250px;
}

.footer-links__link {
    font-size: 15px;
    line-height: 1.325;
    color: $footer-links-color;
}

.footer-links__title {
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: $footer-title-color;
}

.footer-contacts__contacts {
    font-size: 15px;
    line-height: 20px;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction:column;
    gap: 10px;
    // margin: 16px 0px 0 5px;

    a {
        color: inherit;
    }

    li {
        position: relative;
    }
}

.footer-links__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 13px;
    line-height: 20px;
    list-style: none;
    padding: 0;
    margin-bottom: 10px;

    a {
        color: $footer-links-color;
        transition: .15s;
        line-height: 10px;
    }

    a:hover {
        color: $link-hover-color;
    }
}


.footer-contact-text {
    display: flex;
    align-items: baseline;
    color: $footer-links-color;

    i {
        width: 35px !important;
        max-width: 22px;
        margin-right: 5px !important;
    }

    p {
        line-height: 20px;
        margin-bottom: 0rem !important;
    }
}

.footer-contact-text {
    a {
        p:hover {
            color: $link-hover-color;
        }
    }
}

@media (max-width: breakpoint(sm-end)) {
    .site-footer__widgets {
        padding-bottom: 48px;
    }

    .site-footer__widgets2 {
        padding-bottom: 48px;
    }

    .site-footer__bottom {
        padding: 20px 0 24px;
        flex-wrap: wrap;
        height: auto;
    }

    .site-footer__copyright+.site-footer__payments {
        margin-bottom: 24px;
    }

    .site-footer__copyright,
    .site-footer__payments {
        width: 100%;
        text-align: center;
    }
}